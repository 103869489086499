*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Layout */

.grid-container {
  display: grid;
  grid-template-areas:
    "header"
    "main"
    "footer";
  grid-template-columns: 1fr;
  height: 100%;
}
header {
  grid-area: header;
  background-color: #035e26;
}
main {
  grid-area: main;
  padding: 1rem;
}
/* footer {
  grid-area: footer;
  background-color: #203040;
  color: #ffffff;
} */

/* Common */
body {
  background-color: #f3f3f3;
  margin: 0;
  padding: 0;
  font-family: Helvetica, Arial, sans-serif;
}

h1 {
  font-size: 1.8rem;
  margin: 0;
  padding: 0;
  font-family: Helvetica, Arial, sans-serif;
}

h1.abLoopBtn {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}

h1.brand {
  font-size: 1.8rem;
  color: #e9bd94;
  margin: 0;
  padding: 0;
  font-family: Helvetica, Arial, sans-serif;
}

h2 {
  font-size: 1.5rem;
  margin: 0.3rem;
  padding: 0;
  font-family: Helvetica, Arial, sans-serif;
}

h3 {
  font-size: 1rem;
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-family: sans-serif;
}
h3.by {
  text-align: right;
}
a {
  text-decoration: none;
  font-family: Helvetica, Arial, sans-serif;
}
a:hover {
  color: #ff8000;
}
ul {
  font-family: Helvetica, Arial, sans-serif;
  padding: 0;
  margin: 0;
  list-style-type: none;
}
li {
  margin-top: 0.6rem;
}

/* Buttons */

input,
select,
textarea,
button,
.localVid {
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 0.1rem #80cf00 solid;
  font-size: 1rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input.paypal {
  border: none;
  width: 144px;
  background-color: #035e26;
}

#q {
  width: 50vh;
}

button,
.localVid {
  background-color: #f0facb;
  cursor: pointer;
}

button:hover {
  border: 0.1rem #227700 solid;
}
button.primary,
.localVid {
  background-color: #e9bd94;
  border: 0.1rem #e4a84e solid;
  color: #000;
}
button.small {
  font-size: 1.2rem;
  border-radius: 0.3rem;
  padding: 0.1rem 0.5rem;
}
button.tiny {
  margin: 0.05rem;
  font-size: 0.9rem;
  border-radius: 0.3rem;
  padding: 0.1rem 0.2rem;
}

.create-btn {
  position: fixed;
  bottom: 63px;
  right: 9px;
  font-size: 1.8rem;
  border-radius: 0.3rem;
  padding: 0.1rem 0.5rem;
  background-color: rgba(165, 65, 223, 0.6);
  z-index: 200;
}

button.aside-btn {
  position: fixed;

  top: 12px;
  margin-right: 12px;
  font-size: 1.2rem;
  border-radius: 0.3rem;
  padding: 0.1rem 0.5rem;
  z-index: 999;
}

/* Card */
.card {
  border: 0.1rem #c0c0c0 solid;
  background-color: #f8f8f8;
  border-radius: 0.5rem;
  margin: 1rem;
}
.card-body {
  padding: 1rem;
}
.card-body > * {
  margin-bottom: 0.5rem;
}
.price {
  font-size: 2rem;
}

/* Header */
header a {
  color: #ffffff;
  padding: 1rem;
}
a.brand {
  color: #e9bd94;
  font-size: 2.5rem;
  font-weight: bold;
}
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.row.center {
  justify-content: center;
}
.row.top {
  align-items: flex-start;
}
.row.start {
  justify-content: flex-start;
}

.col-1 {
  flex: 1 1 25rem;
}
.col-2 {
  flex: 2 1 50rem;
}
.col-3 {
  flex: 32 1 75rem;
}

/* Dropdown */
.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-content {
  position: absolute;
  display: none;
  right: 0;
  min-width: 12rem;
  padding: 1rem;
  z-index: 1;
  background-color: #203040;
  margin: 0;
  margin-top: 0.4rem;
  border-radius: 0.5rem;
}
.dropdown:hover .dropdown-content {
  display: block;
}

.popover {
  display: inline-block;
  position: relative;
}
.popover-content {
  position: absolute;
  visibility: hidden;
  display: block;

  bottom: 30%;
  padding: 0;
  /* margin-right: -60px; */
  min-width: 9rem;
  z-index: 1;
  background-color: #203040;
  margin: 0;
  border-radius: 0.5rem;
  overflow-y: scroll;
}
.popover:hover .popover-content {
  visibility: visible;
  /* transform: translate(0, -30px); */
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
/* Form */
.form {
  max-width: 33rem;
  margin: 0 auto;
}
.form > div {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}
.form label {
  margin: 1rem 0;
}

/* Aside */
.bm-close {
  position: fixed;
  right: 0;
  /* max-width: 8.5in; */
  background-color: #efefef;
  z-index: 333;
  transform: translateX(100%);
  transition: all 0.5s;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
}
.bm-open {
  /* max-width: 8.5in; */
  width: 49%;
  position: fixed;
  direction: rtl;
  /* transform: translateX(0);
  transition: all 0.5s; */
  right: 0px;
  background-color: #efefef;
  z-index: 333;
  resize: horizontal;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
  padding: 0;
}

/* scrollbar */

::-webkit-scrollbar {
  width: 18px;
}
::-webkit-scrollbar-track {
  background: #033a03;
}

::-webkit-scrollbar-thumb {
  background: rgb(51, 104, 2);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(76, 160, 55);
}

.log-box {
  width: 49%;
  height: 18%;
  position: fixed;
  /* transform: translateX(0);
  transition: all 0.5s; */
  right: 0px;
  z-index: 334;
  overflow-y: scroll;
  bottom: 0;
  padding: 9px;
  border-radius: 0.5rem;
  border: 0.2rem #c0c0c0 solid;
  background: rgba(0, 0, 0, 1);
}

/* .log-close {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 30%;
  margin: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.1);
  z-index: 222;
} */

.mm-close {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 1001;
  transform: translateY(-30rem);
  transition: all 0.5s;
  top: 0;
}
.mm-open {
  transform: translateY(0);
  position: fixed;
  left: 0;
  top: 0;
  padding: 10px;
  width: 100%;
  background-color: #035e26;
  z-index: 1001;
  transition: all 0.5s;
}

/* table */
table {
  width: 100%;
  border-collapse: collapse;
  direction: ltr;
}
table tbody tr:nth-of-type(odd) {
  background-color: #f4f4f4;
}
table tbody tr:nth-of-type(odd):hover {
  background-color: #fdd9c0;
}
table td,
table th {
  text-align: left;
  border: 0.1rem solid #0d5907;
  padding: 0.1rem 0.2rem;
}
table thead,
table th {
  background-color: #c3f8d0;
}

table tr {
  border: 0.1rem solid #0d5907;
}

table tr:hover {
  background-color: #fdd9c0;
}
/* th,
td {
  background: #eee;
  padding: 8px;
} */

table.ab-table {
  background-color: rgba(125, 1, 109, 0.6);
}
table.ab-table tr,
table.ab-table td {
  border: 0.1rem solid rgba(84, 0, 72, 0.3);
  background-color: rgba(30, 0, 26, 0.6);
}

table.ab-table tbody tr:nth-of-type(odd) {
  background-color: rgba(125, 1, 109, 0.6);
}

/* Mobile View */
@media screen and (max-width: 600px) {
  #q {
    width: 111px;
  }

  table {
    margin-top: 24px;
    width: 100%;
  }

  .bm-table {
    transform: rotateX(180deg);
  }
  .ts-table {
    transform: rotateX(180deg);
  }
  table thead {
    display: none;
  }

  /* table tr, */
  table td {
    border-bottom: none;
  }

  table tr {
    border: 0.1rem solid #9acc95;
  }

  table td {
    display: flex;
    border: none;
  }

  table td::before {
    /* font-weight: bold; */
    width: 120px;
    min-width: 120px;
  }

  .container {
    padding: 0;
    margin: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
  }

  .container .ql-editor {
    left: 0;
    z-index: 11;
  }

  .container .ql-container.ql-snow {
    top: 30vh;
    width: 52vh;
    margin-right: 0;
    border: none;
    display: flex;
    justify-content: flex-end;
  }

  .container .ql-toolbar.ql-snow {
    top: 31vh;
  }
  .bm-close {
    top: 45vh;
    right: 0;
    width: 100%;
  }
  .bm-open {
    top: auto;
    bottom: 0;
    width: 100%;
    right: 0px;
    resize: vertical;
    transform: rotateX(180deg);
    direction: ltr;
  }
  .log-box {
    width: 100%;
    height: 12%;
    right: 0;
  }

  button.aside-btn {
    top: 43.6vh;
  }
  .doc-title {
    top: 42vh;
    width: 63%;
    z-index: 999;
  }
}
/* .app-bar {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  z-index: 9;
} */
/* Main */

.hidden {
  display: none;
}

.main-video {
  position: fixed;
  display: flex;
  flex-direction: column;

  margin-right: auto;
  margin-top: -42px;
  left: 3px;
  background: rgba(0, 0, 0, 0);
  z-index: 1000;
}

.ytDrag {
  top: 45px;
  position: fixed;
}

.abloops-wrapper {
  left: 0;
  visibility: visible;
  overflow: auto;
  right: 0;
  top: 0;
  width: auto;
  height: auto;
  background: rgba(100, 144, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.abloops-btns-wrapper {
  width: auto;
  height: 10px;
  transform: rotateY(180deg);
}
.add-loop-btn {
  position: fixed;

  left: 18px;
  color: rgba(255, 110, 236, 0.6);
  border-radius: 30%;
  z-index: 300;
  cursor: pointer;
}

.save-loop-btn {
  position: fixed;
  transform: rotateY(180deg);

  left: 24px;
  color: rgba(255, 110, 236, 0.6);
  border-radius: 30%;
  z-index: 300;
  cursor: pointer;
}

.ctrls-handle {
  position: fixed;
  padding: 5px;
  border-radius: 30%;
  background: rgba(100, 144, 0, 0.3);
  z-index: 300;
  left: 0;
  top: 39px;
  cursor: move;
}
.abloops-toggle {
  position: fixed;
  border-radius: 30%;
  background: rgba(100, 144, 0, 0.3);
  cursor: pointer;
  z-index: 300;
  top: 39px;
  left: 36px;
}

.ctrls-full {
  position: fixed;
  padding: 5px;
  border-radius: 30%;
  background: rgba(100, 144, 0, 0.3);
  z-index: 300;
  left: 0;
  top: 39px;
  left: 72px;
  cursor: pointer;
}

.ctrls-toggle {
  position: fixed;
  border-radius: 30%;
  background: rgba(100, 144, 0, 0.3);
  cursor: pointer;
  z-index: 300;
  top: 39px;
  left: 108px;
}

.bookmark-slot {
  position: fixed;
  right: 0;
  bottom: 0;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.3);
  padding: 10px 42px 18px 10px;
  z-index: 12;
}

.bm-slot-handle {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: move;
}

.bm-slot-toggle {
  position: absolute;
  right: 27px;
  bottom: 0;
  cursor: pointer;
}

.container {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
}

.container .ql-editor {
  width: 98.6vh;
  min-height: 11in;
  padding: 0.3in;
  /* margin: 1rem; */
  right: 0;
  margin-top: 82px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  background-color: white;
}

.container .ql-container.ql-snow {
  border: none;
  display: flex;
  right: -5px;
  justify-content: flex-end;
}

.container .ql-toolbar.ql-snow {
  display: flex;
  justify-content: center;
  position: fixed;
  right: 3px;
  margin-top: 42px;
  height: 0.39in;
  width: 98.6vh;
  z-index: 200;
  background-color: rgba(217, 255, 167, 69%);
  border: none;
  border-radius: 0.2rem;

  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
}
/* toolbar 안에 있는 것들 */
#toolbar {
  justify-content: flex-end;
  padding: 0;
}
#toolbar > button {
  margin: 0.3rem 0;
  padding: 0;
}
#toolbar .ql-header {
  margin: 0.3rem 0;
  width: 42px;
}
.doc-title {
  position: fixed;
  margin-top: 9px;
  left: 51%;
  height: 2.1rem;
  width: 18%;
  font-size: 1.2rem;
  border-radius: 0.3rem;
  padding: 0.1rem 0.5rem;
  z-index: 999;
}

@page {
  margin: 1in;
}

@media print {
  body {
    background: none;
  }

  .container .ql-editor {
    width: 6.5in;
    height: 9in;
    padding: 0;
    margin: 0;
    box-shadow: none;
  }

  .container .ql-toolbar.ql-snow {
    display: none;
  }
}

/* Chatbox */

.ts-line1 {
  height: 45px;
  justify-content: center;

  text-align: center;
  font-size: 1.3rem;
  padding-top: 18px;
  font-family: Helvetica, Arial, sans-serif;
}

.chat-drag {
  position: fixed;
  bottom: 0;
  height: 288px;
  width: 48%;
  left: 45px;
  background: rgba(99, 99, 99, 0);
  z-index: 100;
  resize: both;
  top: auto;
}
.scriptbox-drag {
  position: fixed;
  bottom: 0;
  height: 288px;
  width: 48%;
  left: 45px;
  background: rgba(99, 99, 99, 0);
  z-index: 100;
  resize: both;
  top: auto;
}
.chat-drag .chat-close,
.chat-drag .chat-handle {
  position: absolute;
  left: -45px;
  bottom: 0px;
  width: 45px;
  height: 45px;
  background: #000;
  color: #fff;
  opacity: 1;
  padding: 3px;
  z-index: 100;
  cursor: pointer;
}

.chat-drag .chat-handle {
  left: -45px;
  bottom: 45px;
  padding-top: 8px;
  cursor: move;
}

/* Chatbox */

.scriptbox-drag .scriptbox-close,
.scriptbox-drag .scriptbox-handle {
  position: absolute;
  left: -45px;
  bottom: 0px;
  width: 45px;
  height: 45px;
  background: #000;
  color: #fff;
  opacity: 1;
  padding: 3px;
  cursor: pointer;
}

.scriptbox-drag .scriptbox-handle {
  bottom: 45px;
  padding-top: 8px;
  cursor: move;
}

.scriptbox {
  position: fixed;
  bottom: 0;
  resize: both;
  overflow-y: scroll;
  background: #055505;
  opacity: 0.9;
  width: 100%;
  height: 277px;
  border-radius: 9px;
  display: flex;
  z-index: 10000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "poppins", sans-serif;
}

.chatbox {
  position: fixed;
  transform: rotateX(180deg);
  top: auto;
  bottom: 0;
  resize: both;
  overflow-y: scroll;
  background: #055505;
  opacity: 0.9;
  width: 100%;
  height: 277px;
  border-radius: 9px;
  display: flex;
}

.user-box {
  transform: rotateX(180deg);
  height: auto;
  flex-basis: 27%;
  overflow: scroll;
  padding: 9px;
  background: #183763;
  border-radius: 9px;
}

.user-box h3 {
  text-align: center;
  margin-top: 18px;
  font-weight: 400;
}

.user-box ul {
  list-style: none;
}

.user-box button {
  background-color: transparent;
  border: none;
  margin: 3px;
  padding: 6px;

  text-align: left;
}

.user-box button:hover {
  background: rgb(114, 3, 36);
}
.user-box button.selected-user {
  background: rgb(4, 116, 0);
}

.user-box ul li::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: red;
  display: inline-block;
}

.user-box ul li.online::before {
  background-color: greenyellow;
}
.user-box ul li.offline::before {
  background-color: tomato;
}

.msg-container {
  transform: rotateX(180deg);
  display: grid;
  grid-template-rows: 88% 12%;
  width: 100%;
}
.msg-box {
  overflow-y: scroll;
}

.msg-box li {
  margin-left: 9px;
}

.input-box {
  width: 100%;
}
.chat-input {
  bottom: 0;
  border: 0;
  outline: none;
  width: 82%;
  background: #25569c;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
}

.chat-send {
  width: 18%;
}
::placeholder {
  color: #fff;
}

/* Pagination */
.pagination a {
  padding: 0.5rem;
  margin: 0.3rem;
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 1.3rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pagination a.active {
  font-weight: bold;
  background: #c9f5a5;
}
